import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';

function Serivces() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    };
    const navigation = useNavigate()
    return (
        <div className='mt-5'>
            <div className='bg-service py-3'>
                <div className='my-4 py-lg-5'>
                    <h1 className='text-center fw-bold ff-lobster'>
                        Our Security Services
                    </h1>
                </div>
                <div className='container'>
                    <Row>
                        <Col md={3} className='mt-4 mt-lg-0'>
                            <div className='position-relative w-100 h-100' data-aos="fade-left" onClick={() => {
                                navigation("/event-security")
                            }}>
                                <div className='h-100'>
                                    <img src="https://pikespeaksecurity.org/wp-content/uploads/2021/09/event-security-guard.jpg.webp" alt="" srcset="" className='w-100 rounded object-fit-cover h-100' />
                                </div>
                                <div className='position-absolute top-0 d-flex justify-content-center w-100  align-items-center h-100 overlay'>
                                    <button className='bg-red btn text-uppercase ff-baloo'>
                                        EVENT SECURITY
                                    </button>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} className='mt-4 mt-lg-0'>
                            <div className='position-relative w-100 h-100' onClick={() => {
                                navigation("/industrial-security")
                            }}>
                                <div className='h-100'>
                                    <img src="https://5.imimg.com/data5/SELLER/Default/2023/10/350974247/SN/ES/KJ/90910648/industrial-security-services.jpg" alt="" srcset="" className='w-100 rounded object-fit-cover' height={300} />
                                </div>
                                <div className='position-absolute top-0 d-flex justify-content-center w-100  align-items-center h-100 overlay'>
                                    <button className='bg-red btn text-uppercase ff-baloo'>
                                        INDUSTRIAL SECURITY
                                    </button>
                                </div>
                            </div>
                        </Col>
                        <Col md={3} className='mt-4 mt-lg-0' data-aos="fade-right" onClick={() => {
                            navigation("/residential-security")
                        }}>
                            <div className='position-relative w-100 h-100'>
                                <div className='h-100'>
                                    <img src="https://www.centralprotection.ca/wp-content/uploads/2022/09/residential-security.jpg" alt="" srcset="" className='w-100 rounded object-fit-cover h-100' />
                                </div>
                                <div className='position-absolute top-0 d-flex justify-content-center w-100  align-items-center h-100 overlay'>
                                    <button className='bg-red btn text-uppercase ff-baloo'>
                                        RESIDENTIAL SECURITY
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-lg-4 mt-0'>
                        <Col md={6} className='mt-4 mt-lg-0'>
                            <div className='position-relative w-100 h-100' data-aos="flip-left" onClick={() => {
                                navigation("/mobile-patrol")
                            }}>
                                <div className='h-100'>
                                    <img src="https://cbisecurity.com/wp-content/uploads/2022/04/mobilesecurity-Large.jpeg" alt="" srcset="" className='w-100 rounded object-fit-cover' height={300} />
                                </div>
                                <div className='position-absolute top-0 d-flex justify-content-center w-100  align-items-center h-100 overlay'>
                                    <button className='bg-red btn text-uppercase ff-baloo'>
                                        MOBILE PATROL
                                    </button>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} className='mt-4 mt-lg-0'>
                            <div className='position-relative w-100 h-100' data-aos="flip-right" onClick={() => {
                                navigation("/foot-patrol")
                            }
                            }>
                                <div className='h-100'>
                                    <img src="https://i0.wp.com/grouponesecurity.net.au/wp-content/uploads/2024/08/Foot-patrol-security-services-in-Melbourne-scaled-1.jpg?fit=1792%2C1194&ssl=1" alt="" srcset="" className='w-100 rounded object-fit-cover' height={300} />
                                </div>
                                <div className='position-absolute top-0 d-flex justify-content-center w-100  align-items-center h-100 overlay'>
                                    <button className='bg-red btn text-uppercase ff-baloo'>
                                        FOOT PATROL
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-lg-4 mt-0'>
                        <Col md={3} className='mt-4 mt-lg-0'>
                            <div className='position-relative w-100 h-100' onClick={()=>{
                                navigation("/neighborhood-patrol")
                            }}>
                                <div className='h-100'>
                                    <img src="https://ultimatesecurityservices.ca/wp-content/uploads/2021/04/NEIGHBOURHOOD-WATCH-TORONTO-e1674578671135.jpg" alt="" srcset="" className='w-100 rounded object-fit-cover h-100' />
                                </div>
                                <div className='position-absolute top-0 d-flex justify-content-center w-100  align-items-center h-100 overlay'>
                                    <button className='bg-red btn text-uppercase ff-baloo'>
                                        NEIGHBORHOOD WATCH
                                    </button>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} className='mt-4 mt-lg-0'>
                            <div className='position-relative w-100 h-100' onClick={()=>{
                                navigation("/fire-watch")
                            }}>
                                <div className='h-100'>
                                    <img src="https://www.lawdogsecurity.com/wp-content/uploads/2019/01/Capture-1.jpg" alt="" srcset="" className='w-100 rounded object-fit-cover' height={300} />
                                </div>
                                <div className='position-absolute top-0 d-flex justify-content-center w-100  align-items-center h-100 overlay'>
                                    <button className='bg-red btn text-uppercase ff-baloo'>
                                        Fire WATCH
                                    </button>
                                </div>
                            </div>
                        </Col>
                        <Col md={3} className='mt-4 mt-lg-0'>
                            <div className='position-relative w-100 h-100' onClick={()=>{
                                navigation("/construcion-security")
                            }}>
                                <div className='h-100'>
                                    <img src="https://media.licdn.com/dms/image/C4E12AQGzUXReS9H9lQ/article-cover_image-shrink_600_2000/0/1522911337507?e=2147483647&v=beta&t=SYSLuoR4EuHNEihvYhLcK7unFdlvJdzWPCyiBz6XFcU" alt="" srcset="" className='w-100 rounded object-fit-cover h-100' />
                                </div>
                                <div className='position-absolute top-0 d-flex justify-content-center w-100  align-items-center h-100 overlay'>
                                    <button className='bg-red btn text-uppercase ff-baloo'>
                                        construction SECURITY
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

        </div>
    )
}

export default Serivces
