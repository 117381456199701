import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { TbReport } from "react-icons/tb";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { MdReportGmailerrorred } from "react-icons/md";
import { IoLogoBuffer } from "react-icons/io";
import { GiBorderedShield } from "react-icons/gi";
import { IoMdAlert } from "react-icons/io";
import { MdCrisisAlert } from "react-icons/md";
import { HiMiniBellAlert } from "react-icons/hi2";
import { AiFillAlert } from "react-icons/ai";
import { MdGpsFixed } from "react-icons/md";
import { FaCalendarTimes } from "react-icons/fa";
import { MdViewTimeline } from "react-icons/md";
import { IoTime } from "react-icons/io5";
import { FaBusinessTime } from "react-icons/fa6";
import { MdOutlineAddAlert } from "react-icons/md";
function Why() {
    return (
        <div className='bg-service py-4'>
            <div className='container'>
                <h1 className='text-center fw-bolder ff-lobster my-lg-5'>Why Choose Us for Your Security Needs?</h1>
                <p>
                    If you don’t want to compromise on the security and quality of service, In Action is your best friend. Security services offered by our local security company are of the highest quality and are customized to meet your requirements. If you need residential or professional commercial security services, our team of highly trained security guards is ready to help you. Therefore, we are the best residential security services provider in British Columbia and Ontario because of our professionalism and efficiency. We listen to your needs and offer one-on-one attention. Our commercial security services aim to protect your business property efficiently. We are aware of the problems that companies encounter and offer specific services to solve them. Also, we provide affordable security guard services, meaning anyone can hire professional security. Choose us for professional local security company services and get the kind of security you have never experienced before.
                </p>
                <div className='my-5 py-lg-5'>
                    <Row>
                        <Col className=' mt-3 mt-lg-0' sm={12} lg={4}>
                            <div className='d-flex flex-column gap-3 card p-4' data-aos="flip-right">
                                <h5 className="fw-bolder">
                                    Security Guard Reporting Software
                                </h5>
                                <div className='key-hover cursor-pointer'>
                                    <TbReport className='me-2' />
                                    <span>Daily Activity Reports</span>
                                </div>
                                <div className='key-hover cursor-pointer'>
                                    <HiOutlineDocumentReport className='me-2' />
                                    <span>Incident Reports</span>
                                </div>
                                <div className='key-hover cursor-pointer'>
                                    <MdReportGmailerrorred className='me-2' />
                                    <span>Maintenance Reports</span>
                                </div>
                                <div className='key-hover cursor-pointer'>
                                    <IoLogoBuffer className='me-2' />
                                    <span>Pass On Log</span>
                                </div>
                                <div className='key-hover cursor-pointer'>
                                    <GiBorderedShield className="me-2" />
                                    <span>Post Orders</span>
                                </div>
                            </div>
                        </Col>
                        <Col className=' mt-3 mt-lg-0' sm={12} lg={4}>
                            <div className='d-flex flex-column gap-3 card p-4' data-aos="flip-right">
                                <h5 className="fw-bolder">
                                    Guard Tour Tracking Software
                                </h5>
                                <div className='key-hover cursor-pointer'>
                                    <IoMdAlert className='me-2' />
                                    <span>Get alerts if you officers aren’t active
                                    </span>
                                </div>
                                <div className='key-hover cursor-pointer'>
                                    <MdCrisisAlert className='me-2' />
                                    <span>View guard patrols in real-time</span>
                                </div>
                                <div className='key-hover cursor-pointer'>
                                    <HiMiniBellAlert className='me-2' />
                                    <span>Includes exception reporting</span>
                                </div>
                                <div className='key-hover cursor-pointer'>
                                    <AiFillAlert className='me-2' />
                                    <span>Uses QR codes and RFID/NFC</span>
                                </div>
                                <div className='key-hover cursor-pointer'>
                                    <MdGpsFixed className='me-2' />
                                    <span>GPS technology</span>
                                </div>
                            </div>
                        </Col>
                        <Col className='mt-3 mt-lg-0' sm={12} lg={4}>
                            <div className='d-flex flex-column gap-3 card p-4' data-aos="flip-right">
                                <h5 className="fw-bolder">
                                    Time and Attendance Software
                                </h5>
                                <div className='key-hover cursor-pointer'>
                                    <FaCalendarTimes className='me-2' />
                                    <span>Create schedules online
                                    </span>
                                </div>
                                <div className='key-hover cursor-pointer'>
                                    <MdViewTimeline className='me-2' />
                                    <span>Calculate your officer payroll</span>
                                </div>
                                <div className='key-hover cursor-pointer'>
                                    <IoTime className='me-2' />
                                    <span>Control when officers clock in/out</span>
                                </div>
                                <div className='key-hover cursor-pointer'>
                                    <FaBusinessTime className='me-2' />
                                    <span>Control where officers clock in/out
                                    </span>
                                </div>
                                <div className='key-hover cursor-pointer'>
                                    <MdOutlineAddAlert className='me-2' />
                                    <span>Get alerts for open posts</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default Why
